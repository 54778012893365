import React, { useEffect } from "react";
import webDevelopmentImage from "../Media/service2_web_development.avif";
import frontendImage from "../Media/service2_frontend.jpg";
import backendImage from "../Media/service2_backend.jpg";
import integrationImage from "../Media/service2_integration.jpg";
import ecommerceImage from "../Media/service2_ecommerce.jpg";
import Banner from '../Components/Banner.tsx';
import { useNavigate } from "react-router-dom";
import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

export default function WebDevelopmentServices() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const items = document.querySelectorAll(".services-list li");
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  const handleInquiry = (featureName) => {
    navigate("/inquiry", { state: { preselectedTopic: featureName } });
  };

  return (
    <div className="service">
      <div className="page-content">
        <Banner
          image={webDevelopmentImage}
          textTop="Webentwicklung"
          textBottom="Ein moderner und schlanker Webauftritt für ihr Unternehmen oder Ihre Idee"
          textColor="#ffffff"
        />

<section className="it-partner-section">
<div className="spacer-div-30px"></div>
  <h1>Innovative Web&shy;entwicklung – Mass&shy;geschneidert, Zukunfts&shy;sicher, Leistungs&shy;stark</h1>
  <p>
    Ihre digitale Präsenz ist mehr als nur eine Website – sie ist Ihr Aushängeschild, Ihr Verkaufsraum, Ihr Kommunikations&shy;kanal.  
    Unser erfahrenes Entwickler&shy;team realisiert individuelle, hoch&shy;performante Web&shy;lösungen, die nicht nur optisch überzeugen,  
    sondern auch technologisch Mass&shy;stäbe setzen. Von responsiven Web&shy;applikationen bis hin zu komplexen E-Commerce-Systemen –  
    wir bringen Ihre Vision ins digitale Zeitalter.
  </p>
  <hr></hr>


  <ul className="services-list">

    <li>
      <div className="list-title">
        <strong>Frontend-Entwicklung - Ästhetik trifft auf Benutzer&shy;freund&shy;lich&shy;keit</strong>
      </div>
      <ProgressiveImageCustom src={frontendImage} />
      <div className="list-description">
        <p>
        Wir gestalten moderne, ästhe&shy;tische und intuitiv bedien&shy;bare Web&shy;ober&shy;flächen, die Nut&shy;zer begeis&shy;tern und Ihre Marke profes&shy;sionell repräsen&shy;tieren. Durch unsere Exper&shy;tise in UI-Ent&shy;wick&shy;lung schaffen wir digi&shy;tale Erleb&shy;nisse, die auf jedem End&shy;gerät überzeugen – respon&shy;sive, inter&shy;aktiv und visuell an&shy;sprechend.
        </p>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>Backend-Entwicklung - Leistungs&shy;stark, skalier&shy;bar und sicher</strong>
      </div>
      <ProgressiveImageCustom src={backendImage} />
      <div className="list-description">
        <p>
        Ein solides Backend bildet das zuverlässige Fun&shy;dament Ihrer digi&shy;talen Lösung. Wir entwickeln hoch&shy;performante, sichere und flexibel skalier&shy;bare System&shy;architekturen, die Ihrer Web&shy;anwendung dauerhafte Stabilität und nach&shy;haltige Wachstums&shy;möglichkeiten bieten.

        </p>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>System&shy;integration - Nahtlose Vernetzung für maximale Effizienz</strong>
      </div>
      <ProgressiveImageCustom src={integrationImage} />
      <div className="list-description">
        <p>
          Ihre Web&shy;anwen&shy;dungen müssen perfekt mit CRM-, ERP- oder Cloud-Diensten zusammen&shy;arbeiten?  
          Wir sorgen für eine reibungs&shy;lose Integration und entwickeln leistungs&shy;starke API-Schnitt&shy;stellen,  
          um Ihre Geschäfts&shy;prozesse zu automati&shy;sieren und zu optimieren.
        </p>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>E-Commerce & Webshops - Skalier&shy;bare Online-Shops für nachhal&shy;tigen Erfolg</strong>
      </div>
      <ProgressiveImageCustom src={ecommerceImage} />
      <div className="list-description">

        <p>
          Ob Shopify, WooCommerce oder individuelle E-Commerce-Plattformen –  
          wir bauen leistungs&shy;starke, benutzer&shy;freundliche und skalier&shy;bare Online-Shops.  
          Unser Fokus: Conversion-Optimierung, maximale Sicherheit und erstklassige Performance für nachhaltigen digitalen Erfolg.
        </p>
      </div>
    </li>

  </ul>
</section>



        <button
          className="anfrage-btn not-on-grid"
          onClick={() => handleInquiry("Websites und Webapps")}
        >
          Anfragen
        </button>
      </div>
    </div>
  );
}
