import React, { useEffect } from "react";
import everestImage from "../Media/everest.jpg";
import Banner from '../Components/Banner.tsx';
import ServiceGrid from '../Components/ServiceGrid.tsx';

export default function Service() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target); // Stops observing after first appearance
          }
        });
      },
      { threshold: 0.2 }
    );
    

    const items = document.querySelectorAll(".services-list li");
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="service">
      <div className="page-content">
        <Banner
          image={everestImage}
          textTop="Ihre Herausforderung"
          textBottom="Unsere Stärke"
          textColor="#ffffff"
        />

        <ServiceGrid />
      </div>
    </div>
  );
}
