import ProgressiveImage from "react-progressive-graceful-image";
import React from "react";
import "./ProgressiveImageCustom.css";

export default function ProgressiveImageCustom({ src, placeholder }) {
  return (
    <div className="progressive-image-container">
      <ProgressiveImage delay={300} src={src} placeholder={placeholder}>
        {(src, loading) => (
          <div className="image-wrapper">
            {loading && (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            )}
            <img
              className={`progressive-image ${loading ? "hidden" : "visible"}`}
              src={src}
              alt=""
              style={{ objectFit: "cover" }}
            />
          </div>
        )}
      </ProgressiveImage>
    </div>
  );
}
