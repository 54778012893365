import React, { useEffect } from "react";
import dataImage from "../Media/service6_data_banner.jpg";
import analysisImage from "../Media/service6_data_analysis.avif";
import visualizationImage from "../Media/service6_data_visualization.avif";
import machineLearningImage from "../Media/service6_data_ml.jpg";
import Banner from '../Components/Banner.tsx';
import { useNavigate } from "react-router-dom";
import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

export default function DataScience() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target); // Stops observing after first appearance
          }
        });
      },
      { threshold: 0.2 }
    );
    

    const items = document.querySelectorAll(".services-list li");
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  const handleInquiry = (featureName) => {
    navigate("/inquiry", { state: { preselectedTopic: featureName } });
  };

  return (
    <div className="service">
      <div className="page-content">
        <Banner
          image={dataImage}
          textTop="Data Science Lösungen"
          textBottom="Nutzen Sie das Potenzial Ihrer Daten für fundierte Entscheidungen."
          textColor="#ffffff"
        />

<section className="it-partner-section">
<div className="spacer-div-30px"></div>
  <h1>Individuelle Data&shy;-Science&shy;-Lösungen für Ihr Unternehmen</h1>
  <p>
    In der heutigen digitalen Welt sind Daten der Schlüssel zu fundierten Entscheidungen und nachhaltigem Unternehmenserfolg. 
    Doch Rohdaten allein bieten wenig Mehrwert – erst durch eine gezielte Analyse, professionelle Visualisierung und 
    den Einsatz intelligenter Modelle entstehen wertvolle Erkenntnisse. Wir unterstützen Ihr Unternehmen dabei, das volle Potenzial 
    Ihrer Daten auszuschöpfen, um Prozesse zu optimieren, Wettbewerbsvorteile zu sichern und innovative Geschäftsmodelle zu entwickeln.
  </p>
  <hr></hr>


  <ul className="services-list">
    <li>
      <div className="list-title">
        <strong>Daten&shy;analyse & Erkenntnis&shy;gewinn – Verstehen Sie Ihre Daten auf einer tieferen Ebene</strong>
      </div>
      <ProgressiveImageCustom src={analysisImage} />
      <div className="list-description">
        <p>
          Die systematische Analyse grosser Datenmengen liefert essenzielle Erkenntnisse über Ihre Geschäftsprozesse, 
          Kundenverhalten und Markttrends. Mit fortschrittlichen statistischen Methoden, explorativer Datenanalyse (EDA) 
          und modernen Predictive&shy;-Analytics&shy;-Technologien helfen wir Ihnen, verborgene Muster zu entdecken und 
          datenbasierte Entscheidungen zu treffen. Unsere Lösungen umfassen:
        </p>
        <ul>
          <li>▶ Explorative Datenanalyse zur Identifikation von Trends und Auffälligkeiten</li>
          <li>▶ Statistische Modellierung zur Quantifizierung von Zusammenhängen</li>
          <li>▶ Prädiktive Analysen für präzisere Geschäftsprognosen</li>
          <li>▶ Datenbasierte Entscheidungsmodelle zur Prozessoptimierung</li>
        </ul>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>Daten&shy;visualisierung & Dashboards – Interaktive Insights für fundierte Entscheidungen</strong>
      </div>
      <ProgressiveImageCustom src={visualizationImage} />
      <div className="list-description">
        <p>
          In einer datengetriebenen Welt ist eine effektive Visualisierung entscheidend, um komplexe Zusammenhänge 
          verständlich darzustellen. Wir entwickeln massgeschneiderte interaktive Dashboards, die es Ihnen ermöglichen, 
          Ihre KPIs in Echtzeit zu überwachen, Geschäftsprozesse zu steuern und strategische Entscheidungen zu optimieren. 
          Unsere Lösungen bieten:
        </p>
        <ul>
          <li>▶ Benutzerfreundliche Dashboards mit anpassbaren Filteroptionen</li>
          <li>▶ Echtzeit-Datenvisualisierungen zur schnellen Analyse</li>
          <li>▶ Integration mit gängigen BI-Tools (Tableau, Power BI, D3.js, Plotly)</li>
          <li>▶ Automatisierte Berichterstellung für eine effiziente Entscheidungsfindung</li>
        </ul>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>Machine&shy; Learning & Künstliche&shy; Intelligenz – Smarte Algorithmen für automatisierte Prozesse</strong>
      </div>
      <ProgressiveImageCustom src={machineLearningImage} />
      <div className="list-description">
        <p>
          Machine&shy;-Learning und Künstliche&shy;-Intelligenz revolutionieren die Art und Weise, wie Unternehmen 
          Daten nutzen. Wir entwickeln massgeschneiderte KI-gestützte Lösungen, die Ihre Geschäftsprozesse automatisieren, 
          Effizienz steigern und neue Geschäftsmöglichkeiten eröffnen. Unsere Expertise umfasst:
        </p>
        <ul>
          <li>▶ Entwicklung und Implementierung von Machine&shy;-Learning-Modellen</li>
          <li>▶ Neuronale Netze für komplexe Datenverarbeitung</li>
          <li>▶ Automatische Klassifikations- und Clustering-Verfahren</li>
          <li>▶ Prognosemodelle für optimierte Geschäftsstrategien</li>
        </ul>
      </div>
    </li>
  </ul>
</section>


        <button
                 className="anfrage-btn not-on-grid"
          onClick={() => handleInquiry("Datenanalyse")}
        >
          Anfragen
        </button>
      </div>
    </div>
  );
}
