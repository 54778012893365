import React, { useEffect } from 'react';

const DynamicsMarketingForm = () => {
  // Function to dynamically load scripts
  const loadScript = (src, id) => {
    return new Promise((resolve, reject) => {
      // Check if the script is already loaded
      if (document.getElementById(id)) {
        resolve(`Script ${id} already loaded.`);
        return;
      }

      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => resolve(`Script ${id} loaded successfully.`);
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    // Load the required form script
    const formLoaderScriptUrl = 'https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormLoader/FormLoader.bundle.js';

    loadScript(formLoaderScriptUrl, 'form-loader-script')
      .then((message) => console.log(message))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div>
      <div
        data-form-id='e74048e5-ce0b-f011-bae2-6045bddfb6de'
        data-form-api-url='https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/landingpageforms'
        data-cached-form-url='https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/e74048e5-ce0b-f011-bae2-6045bddfb6de'>
      </div>
      <script>document.documentElement.lang = navigator.language;</script>
    </div>
  );
};

export default DynamicsMarketingForm;
