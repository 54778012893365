import React, { useEffect } from 'react';

const DynamicsMarketingForm = () => {
  useEffect(() => {
    // Load the form loader script dynamically
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Script load error for ${src}`));
        document.body.appendChild(script);
      });
    };

    const formScriptUrl = "https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormLoader/FormLoader.bundle.js";

    loadScript(formScriptUrl)
      .then(() => console.log("Form script loaded successfully."))
      .catch(err => console.error("Failed to load form script:", err));

    // Set document language based on user settings
    document.documentElement.lang = navigator.language;
  }, []);

  return (
    <div>
      <div
        data-form-id="8718472d-31f5-ef11-be1f-000d3ab534be"
        data-form-api-url="https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/landingpageforms"
        data-cached-form-url="https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/8718472d-31f5-ef11-be1f-000d3ab534be"
      ></div>
    </div>
  );
};

export default DynamicsMarketingForm;
