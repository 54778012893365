import React, { useEffect } from "react";
import aiImage from "../Media/service5_ai_banner.jpg";
import automationImage from "../Media/service5_ai_automation.jpg";
import analyticsImage from "../Media/service5_ai_analytics.jpg";
import optimizationImage from "../Media/service5_ai_optimization.jpg";
import Banner from '../Components/Banner.tsx';
import { useNavigate } from "react-router-dom";
import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

export default function ArtificialIntelligence() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target); // Stops observing after first appearance
          }
        });
      },
      { threshold: 0.2 }
    );
    

    const items = document.querySelectorAll(".services-list li");
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  const handleInquiry = (featureName) => {
    navigate("/inquiry", { state: { preselectedTopic: featureName } });
  };

  return (
    <div className="service">
      <div className="page-content">
        <Banner
          image={aiImage}
          textTop="Künstliche Intelligenz"
          textBottom="Entdecken Sie die Zukunft mit massgeschneiderten KI-Lösungen."
          textColor="#ffffff"
        />
<section className="it-partner-section">
<div className="spacer-div-30px"></div>
  <h1>Innovative KI-L&shy;ösungen für eine intelligente Zukunft</h1>
  <p>
    Künstliche Intelli&shy;genz revolutioniert Geschäfts&shy;prozesse und schafft völlig neue Möglich&shy;keiten. 
    Von auto&shy;matisierten Workflows über fort&shy;schrittliche Daten&shy;analysen bis hin zu interaktiven Chatbots 
    – wir entwickeln KI-Lösungen, die Ihre Effizienz steigern, Ihre Kunden&shy;kommunikation optimieren und Ihr 
    Unternehmen in die digitale Zukunft führen.
  </p>
  <hr></hr>


  <ul className="services-list">
    <li>
      <div className="list-title">
        <strong>Intelligente Prozess&shy;auto&shy;matisierung – Effizienz durch KI-gesteuerte Workflows</strong>
      </div>
      <ProgressiveImageCustom src={automationImage} />
      <div className="list-description">
        <p>
          Wiederholende Aufgaben verlangsamen Geschäfts&shy;prozesse und kosten wertvolle Ressourcen. 
          Durch KI-gestützte Automatisierung mit Robotic Process Automation (RPA) und Machine Learning 
          optimieren wir Ihre Workflows und steigern die betriebliche Effizienz erheblich.
        </p>
      </div>
    </li>

    <li>
      <div className="list-title">
        <strong>KI-gestützte Chatbots & Sprach&shy;assistenten – Optimierte Kunden&shy;interaktion</strong>
      </div>
      <div className="list-description">
        <p>
          Unsere KI-basierten Chatbots und Sprach&shy;assistenten ermöglichen eine nahtlose 
          Interaktion mit Kunden. Sie verstehen natür&shy;liche Sprache, beantworten Anfragen 
          in Echtzeit und verbessern die Nutzer&shy;erfahrung, während sie Supportkosten senken.
        </p>
      </div>
    </li>
    
    <li>
      <div className="list-title">
        <strong>Bild- & Video&shy;analyse – Visuelle KI für Sicherheits- und Analyse&shy;zwecke</strong>
      </div>
      <div className="list-description">
        <p>
          Unsere KI-Technologie analysiert Bilder und Videos in Echtzeit, erkennt Muster, Objekte 
          und Gesichter und unterstützt Unternehmen in Bereichen wie Sicherheits&shy;lösungen, 
          Qualitäts&shy;kontrolle und Auto&shy;matisierung.
        </p>
      </div>
    </li>
    
    <li>
      <div className="list-title">
        <strong>Automatische Sprach- & Text&shy;verarbeitung – Smarte Analyse grosser Datenmengen</strong>
      </div>
      <div className="list-description">
        <p>
          KI-gestützte Spracherkennung und NLP-Technologien (Natural Language Processing) ermöglichen 
          die schnelle Transkription, Analyse und Strukturierung von Text- und Sprachdaten. 
          Dies verbessert die Effizienz in der Dokumenten&shy;verarbeitung und Kunden&shy;kommunikation erheblich.
        </p>
      </div>
    </li>
    
    <li>
      <div className="list-title">
        <strong>Predictive Analytics & KI-gestützte Entscheidungs&shy;findung</strong>
      </div>
      <ProgressiveImageCustom src={analyticsImage} />
      <div className="list-description">
        <p>
          Mit fort&shy;schrittlichen KI-Modellen analysieren Sie historische Daten, erkennen Trends 
          und treffen präzise Vorhersagen. Predictive Analytics ermöglicht es Unternehmen, 
          Markt&shy;entwicklungen frühzeitig zu erkennen und strategische Ent&shy;scheidungen daten&shy;gestützt zu treffen.
        </p>
      </div>
    </li>
    
    <li>
      <div className="list-title">
        <strong>KI-gestützte Prozess&shy;optimierung – Maximale Effizienz für Geschäfts&shy;abläufe</strong>
      </div>
      <ProgressiveImageCustom src={optimizationImage} />
      <div className="list-description">
        <p>
          Unsere intelligenten KI-Algorithmen opti&shy;mieren Geschäfts&shy;prozesse in Echtzeit, von 
          dynamischer Preis&shy;gestaltung über Ressourcen&shy;management bis hin zur Supply-Chain-Opti&shy;mierung. 
          Dies reduziert Kosten und steigert die Wettbewerbs&shy;fähigkeit.
        </p>
      </div>
    </li>
  </ul>
</section>


        <button
              className="anfrage-btn not-on-grid"
          onClick={() => handleInquiry("Künstliche Intelligenz")}
        >
          Anfragen
        </button>
      </div>
    </div>
  );
}
