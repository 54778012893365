import React, { useEffect, useRef } from "react";
import LottieAnimation from "../Components/LottieAnimation.tsx";
import img4 from "../Media/ms_pax_8.png";
import "./FeaturesGrid.css";

function FeaturesGrid() {
  const gridRef = useRef(null);

  useEffect(() => {
    const gridElement = gridRef.current;

    if (!gridElement) return;

    const items = gridElement.querySelectorAll(".about-us-item");

    items.forEach((item) => {
      item.classList.add("visible"); // Force items to render correctly
    });
  }, []);

  return (
    <div className="about-us-grid" ref={gridRef}>


      <div className="about-us-item">
        <div className="lottie-animation-wrapper">
          <div className="lottie-animation">
            <LottieAnimation animationKey="animation2" speed={0.9} />
          </div>
        </div>
        <p>
          NextNode ist kein Gigant, daher sind wir in der Lage durch direktere Kommuni&shy;kation sehr schnell zu agieren und rega&shy;gieren und gleich&shy;zeitig mass&shy;geschnei&shy;derte Lösungen zu Ihren Problem&shy;stellungen zu ent&shy;wickeln.
        </p>
      </div>

      <div className="about-us-item">
        <div className="lottie-animation-wrapper">
          <div className="lottie-animation">
            <LottieAnimation animationKey="animation4" speed={0.5} />
          </div>
        </div>
        <p>
        Unser Team und Netz&shy;werk bringt fundierte Erfahr&shy;ung, bewährte Methoden und frische Ideen in Ihr Projekt.  
Gemein&shy;sam entwickeln wir mass&shy;geschneiderte Lösun&shy;gen, die Ihre Ziele pass&shy;genau und nach&shy;haltig umsetzen.

           </p>
      </div>

      <div className="about-us-item">
        <div className="lottie-animation-wrapper">
          <img className="about-us-icon pax8" src={img4} alt="Feature Icon" />
        </div>
        <p>
        Wir sind offi&shy;zieller Micro&shy;soft- und Pax8-Partner und kümmern uns um Ihr Micro&shy;soft-Eco&shy;system, die Ver&shy;waltung Ihrer Lizenzen sowie deren Opti&shy;mierung – effi&shy;zient, trans&shy;par&shy;ent und exakt auf Ihre Be&shy;dürf&shy;nisse zu&shy;ge&shy;schnitten.
        </p>
      </div>
    </div>
  );
}

export default FeaturesGrid;
