import React, { useEffect } from 'react';

const FormWithTracking = () => {
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement('script');
      script.src = src;
      document.body.appendChild(script);
    };

    // Load the external script for the form
    loadScript("https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormLoader/FormLoader.bundle.js");

    // Set the document language
    document.documentElement.lang = navigator.language;

    // Cleanup to remove the script on component unmount
    return () => {
      document.querySelectorAll("script[src^='https://cxppusa1formui01cdnsa01-endpoint.azureedge.net']").forEach(el => el.remove());
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      <div
        data-form-id='edf6166f-92f2-ef11-be1f-000d3ab534be'
        data-form-api-url='https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/landingpageforms'
        data-cached-form-url='https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/edf6166f-92f2-ef11-be1f-000d3ab534be'
      ></div>
    </div>
  );
};

export default FormWithTracking;
